import {EnvironmentInjector, inject, Injectable, runInInjectionContext, Signal, signal} from '@angular/core';
import {CookieOptions, CookieService} from "ngx-cookie-service";
import { environment } from '@epg-apps/cdh-environments-legacy';

import {UserProfile} from '../../index';
import {toSignal} from "@angular/core/rxjs-interop";
import {tap} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";


@Injectable()
export class AuthenticationService {
  static readonly EP_TOKEN = 'EP_TOKEN';
  private readonly EP_ORIG_URL = 'EP_ORIG_URL';
  private readonly EMPTY_PROFILE: UserProfile = {name: '', email: '', roles: [], groups: []};
  private readonly cookieService: CookieService = inject(CookieService)

  private static readonly PROFILE_IAM_API = environment.iamApi + 'profile';
  private injector = inject(EnvironmentInjector);
  private http = inject(HttpClient);

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Version: 'v1.0',
    }),
    params: new HttpParams(),
  };

  profileSignal = signal<UserProfile>(this.EMPTY_PROFILE);

  public getUserProfile(): Signal<UserProfile> {
    if (JSON.stringify(this.profileSignal) !== JSON.stringify(this.EMPTY_PROFILE)) {
      return runInInjectionContext(this.injector, () =>
        toSignal(
          this.http
          .get<UserProfile>(AuthenticationService.PROFILE_IAM_API, this.httpOptions)
          .pipe(
            tap((customers) => this.profileSignal.set(customers))
          ),
          {initialValue: this.EMPTY_PROFILE}
        )
      );
    } else {
      return signal<UserProfile>(this.profileSignal());
    }
  }

  login(redirectParams: string): void {
    this.resetStateAndRedirect(redirectParams);
    window.location.href = environment.authenticationUrl;
  }

  logout(redirectParams: string): void {
    this.resetStateAndRedirect(redirectParams);
    window.location.href = environment.logoutUrl;
  }

  private resetStateAndRedirect(redirectParams: string) {
    this.profileSignal.set(this.EMPTY_PROFILE);
    this.setRedirectCookie(redirectParams);
  }

  private setRedirectCookie(redirectParams: string): void {
    const redirectUrl = environment.baseUrl + redirectParams;
    const oneHourFromNow: Date = new Date();
    oneHourFromNow.setTime(oneHourFromNow.getTime() + 3600_000);
    const cookieOptions: CookieOptions = {
      path: '/',
      domain: 'easypay-group.com',
      expires: oneHourFromNow,
    };
    this.cookieService.set(
      AuthenticationService.mapCookieNameToEnvironment(this.EP_ORIG_URL),
      redirectUrl,
      cookieOptions
    );
  }

  static mapCookieNameToEnvironment(cookieName: string): string {
    return environment.production
      ? cookieName
      : `${environment.version.toUpperCase()}_${cookieName}`;
  }
}
