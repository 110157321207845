import { CDHAppEnvironment, environment } from '@epg-apps/cdh-environments-legacy';
import { NavMenuLinkItem } from '@epg-apps/epg-ui-navbar';

// CDH Application Navigation Menu Items
export const klantenMenuItem: NavMenuLinkItem = {
  label: 'Klanten',
  routerLink: ['/'],
  icon: 'fas fa-address-book',
};

export const werknemerMenuItem: NavMenuLinkItem = {
  label: 'Werknemers',
  routerLink: ['/'],
  icon: 'fas fa-users',
};

export const iamMenuItem: NavMenuLinkItem = {
  label: 'IAM',
  icon: 'fas fa-key',
  items: [
    { label: 'Gebruikers', icon: 'fas fa-user-friends' },
    { label: 'Groepen', icon: 'fas fa-user-cog' },
  ],
};

/**
 * This configuration allows to define which application menu items should be visible based on the environment.
 *  It uses the `environment.version` to determine which menu items to display.
 *  For production and acceptance (`PRD`, 'ACC'), only the `klantenMenuItem` is visible.
 *  For other environment (development), all menu items (`klantenMenuItem`, `werknemerMenuItem`, `iamMenuItem`) are visible.
 */
export const CDHSecondaryMenuConfig: NavMenuLinkItem[] = 
    environment.version === CDHAppEnvironment.DEV ? 

    // Config for DEV
    [klantenMenuItem, werknemerMenuItem, iamMenuItem]

    : // Other Environments: ACC, PRD
    [klantenMenuItem];
