import {
  EnvironmentInjector,
  inject,
  Injectable,
  runInInjectionContext,
  Signal,
  signal,
} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@epg-apps/cdh-environments-legacy';
import { map, tap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { Customer } from './Customer';
import { CustomerDetail } from './CustomerDetail';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  public static readonly EMPTY_CUSTOMER: CustomerDetail = {
    id: '',
    title: '',
    number: 0,
    name: '',
    description: '',
    address: '',
    postalCode: '',
    city: '',
    country: '',
    enterpriseNumber: '',
    responsiblePerson: '',
    customerLegalUnits: [],
  };

  private static readonly CUSTOMER_CDH_API = environment.cdhApi + 'customers';

  http = inject(HttpClient);
  injector = inject(EnvironmentInjector);

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Version: 'v1.0',
    }),
    params: new HttpParams(),
  };

  customers = signal<Customer[]>([]);

  customerDetail = signal<CustomerDetail>(CustomerService.EMPTY_CUSTOMER);

  public getCustomers(query?: string): Signal<Customer[]> {
    const headerOptions = query
      ? { ...this.httpOptions, params: { query } }
      : { ...this.httpOptions };
    return runInInjectionContext(this.injector, () =>
      toSignal(
        this.http
          .get<Customer[]>(CustomerService.CUSTOMER_CDH_API, headerOptions)
          .pipe(
            map((items) =>
              items.map((item) => ({
                ...item,
                title: item.name,
                description: `${item.number}`
              }))
            ),
            tap((customers) => this.customers.set(customers))
          ),
        { initialValue: [] }
      )
    );
  }

  public getCustomer(id: string): Signal<CustomerDetail | undefined> {
    const customersAPi = CustomerService.CUSTOMER_CDH_API + '/' + id;
    return runInInjectionContext(this.injector, () =>
      toSignal(
        this.http.get<CustomerDetail>(customersAPi, this.httpOptions).pipe(
          tap((customerDetail) => this.customerDetail.set(customerDetail))
        )
      )
    );
  }
}
