import {inject, Injectable, signal} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import { environment } from '@epg-apps/cdh-environments-legacy';

/**
 * Data access service to export customer data
 */

@Injectable({
  providedIn: 'root'
})
export class DataAccessCustomerExportService {
  private static readonly CUSTOMER_CDH_API = environment.cdhApi + 'spreadsheet';
  isDownloading = signal<boolean>(false);
  private http = inject(HttpClient);

  public downloadCustomers(): Observable<HttpResponse<Blob>> {
    return this.http.get(DataAccessCustomerExportService.CUSTOMER_CDH_API, {
      headers: new HttpHeaders({
        Version: 'v1.0',
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      }),
      responseType: 'blob',
      observe: 'response'
    })
  }
}
